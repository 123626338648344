import AGREEMENT from "../src/files/agreement.pdf";

var SERVER_URL = "http://127.0.0.1:8000";
var OFFLINE = true;
const ONE_FILE_MODE = true;
const MODE_MENU = false;
const DEFAULT_MODE = "commandclips2";
const ERROR_IF_NOT_EDITED = false;
const REPLACE_WRONG_CORRECT = true;


export { SERVER_URL, OFFLINE, ONE_FILE_MODE, MODE_MENU, DEFAULT_MODE, ERROR_IF_NOT_EDITED, REPLACE_WRONG_CORRECT,
    AGREEMENT };
